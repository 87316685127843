import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { Constants } from "../../Modules/Authentication/utils";
import URLs from "../Constants/URLs";
import { GatewayPropTypes } from "./types";
import {
  getDeviceId,
  performLogout,
  refreshAccessToken,
  validateToken,
} from "../Helpers";

const { SERVICE_CALL_URL } = URLs;

const Gateway = async (props: GatewayPropTypes): Promise<any> => {
  const { payload = {}, type = "POST", url = URLs.SERVICE_CALL_URL } = props;
  let { headers } = props;

  let AccessToken = Cookies.get(Constants.ACCESS_TOKEN);

  if (
    headers &&
    headers.RequestType !== "Login" &&
    (!AccessToken || !validateToken(AccessToken))
  ) {
    console.log("Access token is invalid or expired. Attempting to refresh...");
    AccessToken = (await refreshAccessToken(SERVICE_CALL_URL)) as string;

    if (!AccessToken) {
      performLogout();
      return Promise.reject("Session Expired, Logging out");
    }
  }

  const finalPayload =
    headers?.RequestType === "File" ? payload : { ...(payload || {}) };

  const axiosConfig: AxiosRequestConfig = {
    headers: {
      ...(headers || {}),
      DeviceID: getDeviceId(),
      Authorization: `Bearer ${AccessToken}`,
    },
    data: type === "DELETE" ? finalPayload : undefined,
  };

  console.log(
    "Gateway Status: Before Sending -> ",
    JSON.stringify(finalPayload)
  );

  try {
    let response: AxiosResponse<any>;

    switch (type) {
      case "GET":
        response = await axios.get(url, axiosConfig);
        break;
      case "POST":
        response = await axios.post(url, finalPayload, axiosConfig);
        break;
      case "PUT":
        response = await axios.put(url, finalPayload, axiosConfig);
        break;
      case "PATCH":
        response = await axios.patch(url, finalPayload, axiosConfig);
        break;
      case "DELETE":
        response = await axios.delete(url, axiosConfig);
        break;
      default:
        throw new Error("Invalid request type");
    }

    console.log(
      "Gateway Status: API Call Success -> ",
      JSON.stringify(response.data)
    );

    return response.data;
  } catch (error) {
    console.error("Gateway Status: Error Occurred -> ", error);
    return Promise.reject(error);
  } finally {
    console.log("Gateway Status: Finally Completed");
  }
};

export default Gateway;
