import { createStore, Store } from "redux";
import rootReducer from "./Reducers/index";
import { isProduction } from "../Library/Constants/env";

let store: Store;
const win = window as any;

if (!isProduction) {
  store = createStore(
    rootReducer,
    win.__REDUX_DEVTOOLS_EXTENSION__ && win.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(rootReducer);
}

export default store;
