export {
  getNinType,
  getTinType,
  getTimezones,
  getCurrency,
  getCountry,
  getData,
  getLeType,
  getDimensionTree,
  getFile,
  getMenu,
  getFeatureDoc,
} from "./GetServices";

export {
  createUser,
  submitToDocumentManager,
  uploadFile,
} from "./PostServices";

export {
  manageApplicability,
  manageFormsManager,
  manageWorkflow,
} from "./UtilityServices";
